
body {
  margin: 0;
  user-select: none !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}


/* header navlinks css */
.navbar-nav > a , .nav-item > a{
  position: relative;
  color: #000;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 20px;
}

.navbar-nav > a:hover {
  color: #000;
}
.navbar-nav > a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.navbar-nav > a:not(.active):hover::before {
  transform: scaleX(1);
}
/* .navbar-nav > .active::before {
  transform: scaleX(1);
} */

/* Technology Slide Classes */

.carousel-container{
  height: 100px !important;
} 

/* for technology Slider */
.swiper-button-prev{
  color: #000 !important;
  left: 50px !important;
}
.swiper-button-next{
  color: #000 !important;
  right: 50px !important;
}

@media only screen and (max-width: 500px) {
  .swiper-button-prev{
    left: 30px !important;
  }
  .swiper-button-next{
    right: 30px !important;
  }
  .portfolio_content{
    margin-left: 25px !important;
  }
}
/* For Contact Form */

.contect_form{
  padding-left: 30px;
  padding-right: 30px;
}

.test001{
 border-radius: 0 !important;
 border: none !important; 
 background: rgb(244,244,244) !important; 
 height: 50px !important; 
}

.input_textarea{
  resize: none !important;
  border-radius: 0 !important; 
  border: none !important; 
  background: rgb(244,244,244) !important; 
}
.contect_form_button{
  height: 52px !important; 
  background-color: rgb(51,51,51) !important; 
}

.form-control:focus, select:focus {
  box-shadow: none !important;
}

.Portfolio_Headers:hover{
  cursor: pointer;
}

#basic-navbar-nav .active{
  border-bottom: 2px solid black;
}