@import url('https://fonts.googleapis.com/css?family=Poppins:300');
.title {
  font-weight: 300;
  font-size: 3.7rem;
}
@media only screen and (max-width: 500px) {
  .title {
    font-size: 2.2rem;
  }
}
/* Show Button On image */

.container {
  position: relative;
  /* margin-top: 50px; */
  /* width: 500px; */
  /* height: 300px; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.container:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, .3);
}


.button {
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}
.button > button {
  width: auto;
  padding: 10px 10px;
  text-align: center;
  color: white;
  background: #333333;
  border: 0px;
  z-index: 1;
}
.container:hover .button {
  opacity: 1;
}

.button1 {
  position: absolute;
  left: 0px;
  top: 60px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}
.button1 >button {
  width: auto;
  padding: 10px 10px;
  text-align: center;
  color: white;
  border: 0px;
  background: #333333;
  z-index: 1;
}
.container:hover .button1 {
  opacity: 1;
}

/* hover effect in nav tab */

.navlink {
  position: relative;
  color: #000;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
}

.navlink:hover {
  color: #000;
}
.navlink::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.navlink:hover::before {
  transform: scaleX(1);
 

}

/* Disable Verticla scrolbar */
.homescroll {
  font-family: "Poppins", Helvetica, Arial, Times, serif;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.homescroll::-webkit-scrollbar {
  display: none;
}
/* OurServices */
 .Our_Services_Space{
  max-width: 100%;
  margin-top: 75px;
  margin-bottom: 75px;
 }
 @media only screen and (max-width: 768px) {
  .Our_Services_Space {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
.Slider {
  background: rgb(42, 25, 194); /* cyan */
  /* height:520;  */
  /* width:2000; */
}
@media only screen and (max-width: 768px) {
  .Slider {
    background: #9ff; /* cyan */
    /* height:1000;  */
    /* width:2000; */
  }
}


/* social media icon */

.icon{
  padding:8px 13px;
  border-radius:100px;
  background:#ccc;
  margin-left:5px;
  margin-right:5px;
}
.icon:hover{
  background:#333;
}
.icon:hover>svg path{
  color:#fff;
}


/* class for Details page */

.container1 {
  position: relative;
  width: 450px;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.container1:hover .overlay1 {
  display: block;
  background:  rgba(255, 255, 255, 0.7);
}


.text1 {
  position: absolute;
  left: 20px;
  top: 250px;
  font-size: 21px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}
.text1 > p {
  text-decoration: none;
  text-align: center;
  color: black;
  z-index: 1;
}
.container1:hover .text1 {
  opacity: 1;
}

.text2 {
  position: absolute;
  left: 20px;
  top: 280px;
  font-size: 16px;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}
.text2 >p {
  text-decoration: none;
  text-align: center;
  color: black;
  z-index: 1;
}
.container1:hover .text2 {
  opacity: 1;
}

